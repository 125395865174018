import { createContext } from 'react'
import { Product, SellRequest, User } from 'model'

export interface SellRequestContextValue {
  isLoading?: boolean,
  sellRequest?: SellRequest,
  seller?: User,
  statusBy?: User,
  acceptedBy?: User,
  product?: Product,
  updateSellRequest: (data: Partial<SellRequest>) => Promise<SellRequest | undefined>,
}

const SellRequestContext = createContext<SellRequestContextValue | undefined>(undefined)

export default SellRequestContext
