import { DetailItem } from 'core/components'
import { SellRequest } from 'model'
import { Stack } from 'ui/core'

export interface SellRequestRejectionReasonProps {
  sellRequest?: SellRequest,
}

const SellRequestRejectionReason = ({ sellRequest }: SellRequestRejectionReasonProps) => (
  <Stack>
    {sellRequest?.status?.reason && (
      <DetailItem label="Rejection reason" value={sellRequest?.status?.reason?.name} />
    )}
    {sellRequest?.status?.notes && (
      <DetailItem label="Rejection notes" value={sellRequest?.status?.notes} />
    )}
  </Stack>
)

export default SellRequestRejectionReason
