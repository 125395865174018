import { SellRequest } from 'model'
import { Header } from 'core/components'
import { SellRequestStatus } from '../SellRequestStatus'

interface SellRequestTitleProps {
  sellRequest?: SellRequest,
  onClickBack?: () => void,
}

const SellRequestTitle = ({ sellRequest, onClickBack }: SellRequestTitleProps) => (
  <Header title={sellRequest?.product?.title} onClickBack={onClickBack}>
    {sellRequest?.status?.name && <SellRequestStatus status={sellRequest.status.name} />}
  </Header>
)

export default SellRequestTitle
