import { arrayUnion, updateDoc } from 'firebase/firestore'
import { uploadBytes } from 'firebase/storage'
import { SellRequest, SellRequestImage } from 'model'
import useTask, { TaskState } from '@resellam/hooks/hooks/use-task'
import { getDocumentRef, getStorageRef } from '@resellam/firebase'
import { sellRequestConverter } from '../../utils/converter'

const UPDATEDABLE_PROPS = [
  'repairs',
  'damages',
  'additionalInfo',
  'imeiNumber',
  'serialNumber',
  'batteryHealth',
  'variants',
] as const
type UpdateablePropsTuple = typeof UPDATEDABLE_PROPS
type UpdateableProps = UpdateablePropsTuple[number]

export type UpdateSellRequest = Pick<SellRequest, 'id' | UpdateableProps> & {
  images?: { file: File, isReceipt: boolean, side?: string }[],
}

const updateSellRequest = async (sellRequest: UpdateSellRequest): Promise<SellRequest> => {
  const docRef = getDocumentRef('sell-requests', sellRequest.id).withConverter(sellRequestConverter)

  const data = Object.entries(sellRequest).reduce((acc, [key, val]) => {
    if (UPDATEDABLE_PROPS.includes(key as UpdateableProps)) {
      acc[key] = val
    }
    return acc
  }, {} as Record<string, any>)

  let images: SellRequestImage[] | undefined

  if (sellRequest.images) {
    const uploadResult = await Promise.all(
      sellRequest.images.map(({ file }) =>
        uploadBytes(getStorageRef('sell-requests', docRef.id, file.name), file, {
          customMetadata: {
            sellRequestId: docRef.id,
          },
        }),
      ),
    )
    images = uploadResult.map((result, index) => ({
      name: result.ref.name,
      fullPath: result.ref.fullPath,
      contentType: result.metadata.contentType || undefined,
      isReceipt: sellRequest.images?.[index].isReceipt,
    }))
    data.images = arrayUnion(...images)
  }

  await updateDoc(docRef, data)

  return { ...data, images, id: sellRequest.id } as SellRequest
}

const useUpdateSellRequest = (): [
  (sellRequest: UpdateSellRequest) => Promise<SellRequest | null>,
  TaskState<SellRequest>,
] => {
  const [taskFn, taskState] = useTask<SellRequest>()
  const update = (sellRequest: UpdateSellRequest) => taskFn(() => updateSellRequest(sellRequest))
  return [update, taskState]
}

export default useUpdateSellRequest
