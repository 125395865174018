import { Product, SellRequest, SellRequestVariant } from 'model'
import { IconButton, Flex, Box, Icon } from 'ui/core'
import {
  ProductDetailWrapper,
  SectionHeader,
  DetailList,
} from 'core/components'
import { formatTestID, formatVariantValue } from 'core/utils'
import { useModals } from 'ui/modals'
import { useAuth } from '@resellam/auth'
import { EditSellRequestDetails } from './EditSellRequestDetails'
import { SellRequestDetailsFormProvider } from '../../contexts'
import { useMemo } from 'react'
import { useGetDocument } from '@aonawale/react-sanity-hooks'
import { getSanityClient, PRODUCT_FIELDS } from '@resellam/sanity'

type ProductDetailProps = {
  productId?: string,
  product?: Product,
  variants?: SellRequestVariant[],
  serialNumber?: string,
  imeiNumber?: string,
  batteryHealth?: number,
  testID?: string,
  imageSize?: number,
}

const ProductDetail = ({
  product: _product,
  productId,
  variants,
  serialNumber,
  imeiNumber,
  batteryHealth,
  imageSize,
  testID,
}: ProductDetailProps) => {
  const { data, isLoading } = useGetDocument<Product>(
    getSanityClient(),
    !_product && productId ? { id: productId } : undefined,
    PRODUCT_FIELDS,
  )

  const product = _product ?? data

  const items = useMemo(
    () => [
      {
        label: 'Device',
        value: product?.title,
        testID: formatTestID(testID, 'device'),
      },
      ...(variants?.map((variant) => ({
        label: variant.label,
        value: formatVariantValue(variant.value),
        testID: formatTestID(testID, variant.label),
      })) || []),
      ...(batteryHealth
        ? [
            {
              label: 'Battery health',
              value: `${batteryHealth}%`,
              testID: formatTestID(testID, 'battery-health'),
            },
          ]
        : []),
      ...(serialNumber
        ? [
            {
              label: 'Serial number',
              value: serialNumber,
              testID: formatTestID(testID, 'serial-number'),
            },
          ]
        : []),
      ...(imeiNumber
        ? [
            {
              label: 'IMEI',
              value: imeiNumber,
              testID: formatTestID(testID, 'imei-number'),
            },
          ]
        : []),
    ],
    [product, variants, batteryHealth, serialNumber, imeiNumber, testID],
  )

  return (
    <ProductDetailWrapper
      loading={isLoading}
      product={product}
      testID={testID}
      imageSize={imageSize}
    >
      <DetailList
        items={items}
        wrap="wrap"
        direction={{ base: 'row', sm: 'column' }}
        justify={{ base: 'space-between', sm: 'unset' }}
      />
    </ProductDetailWrapper>
  )
}

export interface SellRequestProductDetailProps {
  product?: Product,
  swapProduct?: Product,
  sellRequest?: Partial<SellRequest>,
  testID?: string,
  onEdit?: (data: SellRequest) => void,
}

const SellRequestProductDetail = ({
  product,
  swapProduct,
  sellRequest,
  onEdit,
  testID,
}: SellRequestProductDetailProps) => {
  const modals = useModals()
  const auth = useAuth()

  const openEditModal = () => {
    const id = modals.openModal({
      title: 'Edit Request',
      children: sellRequest?.id && (
        <SellRequestDetailsFormProvider
          product={product}
          initialValues={sellRequest}
        >
          <EditSellRequestDetails
            product={product}
            sellRequest={sellRequest as Required<SellRequest>}
            onCancel={() => modals.closeModal(id)}
            onSave={(data) => {
              modals.closeModal(id)
              onEdit?.(data)
            }}
          />
        </SellRequestDetailsFormProvider>
      ),
    })
  }

  const imageSize = sellRequest?.upgrade ? 280 : undefined

  return (
    <div data-testid={testID}>
      <SectionHeader
        title="Details"
        action={
          auth.isAdmin
          && onEdit && (
            <IconButton
              name="edit"
              title="Edit"
              onClick={openEditModal}
              testID={formatTestID(testID, 'edit')}
            />
          )
        }
      />
      <Flex gap="md" w="100%" direction={{ base: 'column', sm: 'row' }}>
        <ProductDetail
          product={product}
          productId={sellRequest?.product?.id}
          variants={sellRequest?.variants}
          batteryHealth={sellRequest?.batteryHealth}
          imeiNumber={sellRequest?.imeiNumber}
          serialNumber={sellRequest?.serialNumber}
          imageSize={imageSize}
          testID={testID}
        />
        {sellRequest?.upgrade && (
          <>
            <Box lh={0} style={{ flexShrink: 0, alignSelf: 'center' }}>
              <Icon name="repeat" size={28} />
            </Box>
            <ProductDetail
              product={swapProduct}
              productId={sellRequest?.upgrade?.productId}
              variants={sellRequest?.upgrade?.variants}
              imageSize={imageSize}
              testID={testID}
            />
          </>
        )}
      </Flex>
    </div>
  )
}

export default SellRequestProductDetail
