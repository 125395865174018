import { Text, Group, Badge, createPolymorphicComponent, ButtonCard, ButtonCardProps, Stack } from 'ui/core'
import { SellRequest } from 'model'
import { forwardRef } from 'react'
import { formatVariantValue } from 'core/utils'
import { SellRequestStatus } from '../SellRequestStatus'

export interface SellRequestCardProps extends Omit<ButtonCardProps, 'children'> {
  sellRequest: SellRequest,
  compact?: boolean,
}

const SellRequestCard = forwardRef<HTMLButtonElement, SellRequestCardProps>(
  ({ sellRequest, compact, ...rest }, ref) => (
    <ButtonCard ref={ref} {...rest}>
      <Stack gap="sm" w="100%">
        <Group justify="space-between" wrap="nowrap" w="100%">
          <Text lineClamp={1}>{sellRequest.product?.title}</Text>
          {sellRequest.status?.name && <SellRequestStatus status={sellRequest.status.name} />}
        </Group>
        {!compact && sellRequest.variants?.length ? (
          <Group wrap="nowrap" gap="xs">
            {sellRequest.variants?.map(({ value }) => (
              <Badge key={value} color="gray" variant="light">
                {formatVariantValue(value)}
              </Badge>
            ))}
          </Group>
        ) : null}
      </Stack>
    </ButtonCard>
  ),
)

export default createPolymorphicComponent<'button', SellRequestCardProps>(SellRequestCard)
