import { Dropzone } from 'core/components'
import { ReactNode, forwardRef, useRef } from 'react'
import { Menu } from 'ui/core'
import { formatTestID } from 'core/utils'

interface PhotosActionItemProps {
  icon?: ReactNode,
  children: ReactNode,
  disabled?: boolean,
  multiple?: boolean,
  onDrop: (files: File[]) => void,
  testID?: string,
}

const PhotosActionItem = forwardRef<HTMLButtonElement, PhotosActionItemProps>(
  ({ icon, children, multiple, disabled, testID, onDrop }, ref) => {
    const openDropzoneRef = useRef<() => void>(null)
    return (
      <Menu.Item
        ref={ref}
        leftSection={icon}
        disabled={disabled}
        onClick={() => openDropzoneRef?.current?.()}
        data-testid={testID}
      >
        <Dropzone
          unstyled
          multiple={multiple}
          activateOnClick={false}
          dragEventsBubbling={false}
          openRef={openDropzoneRef}
          style={{ cursor: 'pointer' }}
          onDrop={onDrop}
          testID={formatTestID(testID, 'dropzone')}
        >
          {children}
        </Dropzone>
      </Menu.Item>
    )
  },
)

export default PhotosActionItem
