import { SellRequest } from 'model'
import { DetailItem, SectionHeader } from 'core/components'

export interface SellRequestProductMoreInfoProps {
  sellRequest?: Partial<SellRequest>,
  testID?: string,
}

const SellRequestProductMoreInfo = ({
  sellRequest,
  testID,
}: SellRequestProductMoreInfoProps) =>
  sellRequest?.additionalInfo ? (
    <div data-testid={testID}>
      <SectionHeader title="Additional Information" />
      <DetailItem value={sellRequest?.additionalInfo} />
    </div>
  ) : null

export default SellRequestProductMoreInfo
