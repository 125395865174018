import { SellRequestStatusName } from 'model'
import { Badge, MantineColor, MantineSize } from 'ui/core'

const statusColorMap = new Map<SellRequestStatusName, MantineColor>([
  ['SUBMITTED', 'yellow'],
  ['ACCEPTED', 'green'],
  ['REJECTED', 'red'],
  ['CANCELLED', 'red'],
  ['COMPLETED', 'blue'],
  ['EXPIRED', 'gray'],
])

const SellRequestStatus = ({ status, size }: { size?: MantineSize, status: SellRequestStatusName }) => (
  <Badge
    size={size}
    color={statusColorMap.get(status)}
    variant="outline"
    style={{ flexShrink: 0 }}
  >
    {status}
  </Badge>
)

export default SellRequestStatus
