import { SellRequestIssue as SellRequestIssueModel } from 'model'
import { Text, Card, Flex } from 'ui/core'
import { forwardRef } from 'react'
import { Image } from 'core/components'

export interface SellRequestIssueCardProps {
  issue: SellRequestIssueModel,
  testID?: string,
}

const SellRequestIssueCard = forwardRef<HTMLDivElement, SellRequestIssueCardProps>(
  ({ issue, testID, ...rest }, ref) => {
    return (
      <Card
        component={Flex}
        // @ts-expect-error
        align="center"
        gap="sm"
        direction="column"
        justify="center"
        ref={ref}
        data-testid={testID}
        {...rest}
      >
        {issue.part.image && (
          <Image
            src={issue.part.image}
            alt={issue.part.name}
            width={48}
            height={48}
          />
        )}
        <Text align="center">{issue.part.name}</Text>
        <Text align="center" color="gray" size="sm">
          {issue.description}
        </Text>
      </Card>
    )
  },
)
export default SellRequestIssueCard
